import React, { createContext, useState } from 'react';

// Create the context
export const AdminContext = createContext();

// Create a provider for components to consume and share the admin email
export const AdminProvider = ({ children }) => {
  const [adminEmail, setAdminEmail] = useState('');
  const [userEmail,setUserEmail]=useState('');
  const [userLoggedIn,setUserLoggedIn]=useState(false);

  return (
    <AdminContext.Provider value={{ adminEmail, setAdminEmail,userEmail,setUserEmail,userLoggedIn,setUserLoggedIn }}>
      {children}
    </AdminContext.Provider>
  );
};

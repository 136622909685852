
// import React, { useRef, useEffect, useState } from "react";
// import { Container } from "reactstrap";
// import { NavLink, Link } from "react-router-dom";
// import "../Styles/header.css";
// import logo from "../../assets/images/logo-bgremoved.png";
// import Carts from "../Cart/Carts"; // Import the Carts component

// const nav__links = [
//   {
//     display: "Home",
//     path: "/home",
//   },
//   {
//     display: "Our-Products",
//     path: "/products",
//   },
//   {
//     display: "Cart",
//     path: "/",
//   },
//   {
//     display: "Contact",
//     path: "/contact",
//   },
// ];

// const Header = () => {
//   const menuRef = useRef(null);
//   const headerRef = useRef(null);
//   const [totalQuantity, setTotalQuantity] = useState(0);
//   const [cartIsVisible, setCartIsVisible] = useState(false); // State for cart visibility
//  const [cartItems,setCartItems]=useState([]);
//   const toggleMenu = () => menuRef.current.classList.toggle("show__menu");

//   const toggleCart = () => {
//     setCartIsVisible(!cartIsVisible);
//   };

//   useEffect(() => {
//     const handleScroll = () => {
//       if (
//         document.body.scrollTop > 80 ||
//         document.documentElement.scrollTop > 80
//       ) {
//         headerRef.current.classList.add("header__shrink");
//       } else {
//         headerRef.current.classList.remove("header__shrink");
//       }
//     };
  
//     window.addEventListener("scroll", handleScroll);
  
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   return (
//     <header className="header" ref={headerRef}>
//       <Container>
//         <div className="nav__wrapper d-flex align-items-center justify-content-between">
//           <div className="logo">
//             <img src={logo} alt="logo" />
//           </div>

//           {/* ======= menu ======= */}
//           <div className="navigation" ref={menuRef} onClick={toggleMenu}>
//             <div className="menu d-flex align-items-center gap-5">
//               {nav__links.map((item, index) => (
//                 <NavLink
//                   to={item.path}
//                   key={index}
//                   className={(navClass) =>
//                     navClass.isActive ? "active__menu" : ""
//                   }
//                 >
//                   {item.display}
//                 </NavLink>
//               ))}
//             </div>
//           </div>

//           {/* ======== nav right icons ========= */}
//           <div className="nav__right d-flex align-items-center gap-4">
//             <span className="cart__icon" onClick={toggleCart}>
//               <i className="ri-shopping-basket-line"></i>
//               <span className="cart__badge">{totalQuantity}</span>
//             </span>

//             <span className="user">
//               <Link to="/login">
//                 <i className="ri-user-line"></i>
//               </Link>
//             </span>

//             <span className="mobile__menu" onClick={toggleMenu}>
//               <i className="ri-menu-line"></i>
//             </span>
//           </div>
//         </div>
//       </Container>
//       {/* Render the Carts component conditionally based on cart visibility state */}
//       {cartIsVisible && <Carts cartIsVisible={cartIsVisible} toggleCart={toggleCart}  cartItems={cartItems}/>}

//     </header>
//   );
// };

// export default Header;


// // this is working finr code for the add to cart for two context api
// import React, { useRef, useEffect, useContext } from "react";
// import { Container } from "reactstrap";
// import { NavLink, Link } from "react-router-dom";
// import { CartContext } from "../Cart/CartContext"; // Import the CartContext
// import "../Styles/header.css";
// import logo from "../../assets/images/logo-bgremoved.png";
// import Carts from "../Cart/Carts";

// const nav__links = [
//   {
//     display: "Home",
//     path: "/home",
//   },
//   {
//     display: "Our-Products",
//     path: "/products",
//   },
//   {
//     display: "Cart",
//     path: "/",
//   },
//   {
//     display: "Contact",
//     path: "/contact",
//   },
// ];

// const Header = () => {
//   const menuRef = useRef(null);
//   const headerRef = useRef(null);
//   const { cartIsVisible, toggleCart } = useContext(CartContext); // Access context
  
//   const toggleMenu = () => menuRef.current.classList.toggle("show__menu");

//   useEffect(() => {
//     const handleScroll = () => {
//       if (
//         document.body.scrollTop > 80 ||
//         document.documentElement.scrollTop > 80
//       ) {
//         headerRef.current.classList.add("header__shrink");
//       } else {
//         headerRef.current.classList.remove("header__shrink");
//       }
//     };

//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   return (
//     <header className="header" ref={headerRef}>
//       <Container>
//         <div className="nav__wrapper d-flex align-items-center justify-content-between">
//           <div className="logo">
//             <img src={logo} alt="logo" />
//           </div>

//           <div className="navigation" ref={menuRef} onClick={toggleMenu}>
//             <div className="menu d-flex align-items-center justify-content-start gap-5">
//               {nav__links.map((item, index) => (
//                 <NavLink
//                   to={item.path}
//                   key={index}
//                   className={(navClass) =>
//                     navClass.isActive ? "active__menu" : ""
//                   }
//                 >
//                   {item.display}
//                 </NavLink>
//               ))}
//             </div>
//           </div>

//           <div className="nav__right d-flex align-items-center gap-4">
//             <span className="cart__icon" onClick={toggleCart}>
//               <i className="ri-shopping-basket-line"></i>
//               <span className="cart__badge">{/* totalQuantity */}</span>
//             </span>

//             <span className="user">
//               <Link to="/login">
//                 <i className="ri-user-line"></i>
//               </Link>
//             </span>

//             <span className="mobile__menu" onClick={toggleMenu}>
//               <i className="ri-menu-line"></i>
//             </span>
//           </div>
//         </div>
//       </Container>
//       {cartIsVisible && <Carts />}
//     </header>
//   );
// };

// export default Header;






/*

// this is working finr code for the add to cart for two context api and normal header
import React, { useRef, useEffect, useContext } from "react";
import { Container } from "reactstrap";
import { NavLink, Link } from "react-router-dom";
import { CartContext } from "../Cart/CartContext";
import { useData } from "../Cart/CartDataManage/dataManageContext";
import "../Styles/header.css";
import logo from "../../assets/images/logo-bgremoved.png";
import Carts from "../Cart/Carts";

const nav__links = [
  {
    display: "Home",
    path: "/",
  },
  {
    display: "Menu",
    path: "/menu",
  },
  {
    display: "Our Products",
    path: "/ourproducts",
  },
  {
    display:"Dine In",
    path:"/dinein"
  }
 
];

const adminNavLinks = [
  {
    display: "Home",
    path: "/",
  },
];
const Header = () => {
  const menuRef = useRef(null);
  const headerRef = useRef(null);
  const { cartIsVisible, toggleCart } = useContext(CartContext); // Access context

const { totalQuantity } = useData();
  const toggleMenu = () => menuRef.current.classList.toggle("show__menu");

  useEffect(() => {
    const handleScroll = () => {
      if (
        document.body.scrollTop > 80 ||
        document.documentElement.scrollTop > 80
      ) {
        headerRef.current.classList.add("header__shrink");
      } else {
        headerRef.current.classList.remove("header__shrink");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className="header" ref={headerRef}>
      <Container>
        <div className="nav__wrapper d-flex align-items-center justify-content-between">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>

          <div className="navigation" ref={menuRef} onClick={toggleMenu}>
            <div className="menu d-flex align-items-center justify-content-start gap-5">
              {nav__links.map((item, index) => (
                <NavLink
                  to={item.path}
                  key={index}
                  className={(navClass) =>
                    navClass.isActive ? "active__menu" : ""
                  }
                >
                  {item.display}
                </NavLink>
              ))}
            </div>
          </div>

       
             <div className="nav__right d-flex align-items-center gap-4">
            
              <>
                <span className="cart__icon" onClick={toggleCart}>
                  <i className="ri-shopping-basket-line"></i>
                  <span className="cart__badge">{totalQuantity}</span>
                </span>

                <span className="user">
                  <Link to="/user-register">
                    <i className="ri-user-line"></i>
                  </Link>
                </span>
              </>


          

            <span className="mobile__menu" onClick={toggleMenu}>
              <i className="ri-menu-line"></i>
            </span>
          </div>
        </div>
      </Container>
      {cartIsVisible && <Carts />}
      
    </header>
  );
};

export default Header;
*/

import React, { useRef, useEffect, useContext,useState } from "react";
import { Container } from "reactstrap";
import { NavLink, Link } from "react-router-dom";
import { CartContext } from "../Cart/CartContext";
import { AdminContext } from '../Pages/Admin-Section/AdminContext';
import { useData } from "../Cart/CartDataManage/dataManageContext";
import "../Styles/header.css";
import logo from "../../assets/images/Mr._Champaran-Updated_Logo-nobg.png";
import Carts from "../Cart/Carts";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BASE_URL from "../../config";

const  admin_nav__links = [
  {
    display: "Home",
    path: "/admin-home",
  },

  {
    display: "Add-Products",
    path: "/v1/auth/admin/addproducts"
  },
  {
    display: "Add-Menu",
    path: "/v1/auth/admin/addmenu"
  },
  {
    display:"Manage-Products",
    path:"/v1/auth/admin/manageproducts"
  },
  {
    display:"Manage-Menu",
    path:"/v1/auth/admin/managemenu"
  }
];

const  user_nav__links= [
  {
    display: "Home",
    path: "/",
  },
  {
    display: "Menu",
    path: "/menu-items",
  },
  {
    display: "Our Products",
    path: "/ourproducts",
  },

  
];
const Header = () => {
  
  const menuRef = useRef(null);
  const headerRef = useRef(null);
  const [redirect,setRedirect]=useState(false);
  const { cartIsVisible, toggleCart } = useContext(CartContext); // Access context

const { totalQuantity } = useData();
  const toggleMenu = () => menuRef.current.classList.toggle("show__menu");

  const [isAdminAccessible,setIsAdminAccessible]=useState(false);
  const [isUserLoggedIn,setIsUserLoggedIn]=useState(false);
  const { userLoggedIn: isUserLoggedInContext } = useContext(AdminContext);
const navigate =useNavigate();
  useEffect(() => {
    const handleScroll = () => {
      if (
        document.body.scrollTop > 80 ||
        document.documentElement.scrollTop > 80
      ) {
        headerRef.current.classList.add("header__shrink");
      } else {
        headerRef.current.classList.remove("header__shrink");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  const { adminEmail: adminEmailFromContext, setAdminEmail } = useContext(AdminContext);
  const adminEmailFromLocalStorage = localStorage.getItem('adminEmail');
  const adminEmail = adminEmailFromContext || adminEmailFromLocalStorage;
  useEffect(() => {
    if (adminEmail) {
      // Fetch admin accessibility status
      fetchAdminAccessibility(adminEmail);
    }
  }, [adminEmail]);

  const fetchAdminAccessibility = (email) => {
    fetch(`${BASE_URL}/api/admin/checkEmail`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email }),
    })
      .then((response) => response.json())
      .then((data) => {
        setIsAdminAccessible(data.exists && data.verified);
      })
      .catch((error) => {
        console.error('Error checking admin email:', error);
        setIsAdminAccessible(false);
      });
  };
  const handleLogout=()=>{
    setIsAdminAccessible(false);
    localStorage.removeItem('adminEmail');
 
  }
  useEffect(() => {
    // Get user login status from local storage
    const isUserLoggedInStorage = localStorage.getItem("isUserLoggedIn") === "true";

    // Combine user login status from local storage and context
    setIsUserLoggedIn(isUserLoggedInStorage || isUserLoggedInContext);
  }, [isUserLoggedInContext]); 
  
  const handleUserLogout =()=>{
    setIsUserLoggedIn(false);
    localStorage.removeItem("isUserLoggedIn");
    toast.success("User-Logged out Successfully");
    navigate('/user-login')
  }

  return (
    <header className="header" ref={headerRef}>
      <Container>
        <div className="nav__wrapper d-flex align-items-center justify-content-between">
          <div className="logo" style={{height:'120px',width:'130px'}}>
            <img src={logo} alt="logo" />
          </div>

          <div className="navigation" ref={menuRef} onClick={toggleMenu}>
            <div className="menu d-flex align-items-center justify-content-start gap-5">
              {isAdminAccessible ? (
                admin_nav__links.map((item, index) => (
                  <NavLink
                    to={item.path}
                    key={index}
                    className={(navClass) =>
                      navClass.isActive ? "active__menu" : ""
                    }
                  >
                    {item.display}
                  </NavLink>
                ))
              ) : (
                user_nav__links.map((item, index) => (
                  <NavLink
                    to={item.path}
                    key={index}
                    className={(navClass) =>
                      navClass.isActive ? "active__menu" : ""
                    }
                  >
                    {item.display}
                  </NavLink>
                ))
              )}
            </div>
          </div>

       
          <div className="nav__right d-flex align-items-center gap-4">
            {!isAdminAccessible ? (
              <>
              {isUserLoggedIn ?(
                <>
              
            
                <div className="nav__right d-flex align-items-center gap-4">
            
          
              <span className="cart__icon" onClick={toggleCart}>
                <i className="ri-shopping-basket-line"></i>
                <span className="cart__badge">{totalQuantity}</span>
              </span>

              <span className="user">
                <Link to="/user-register">
                  <i className="ri-user-line"></i>
                </Link>
              </span>
          <span className="mobile__menu" onClick={toggleMenu}>
            <i className="ri-menu-line"></i>
          </span>
          <span onClick={handleUserLogout}  className="user-logout">
          User-Logout
          </span>
        </div>
        
</>
        ):(
                <>
              <div className="nav__right d-flex align-items-center gap-4">
  <a 
    className="cart__icon" 
    href="https://www.instagram.com/mrchamparan/"
    target="_blank"
    rel="noopener noreferrer"
  >
    <i className="ri-instagram-line" style={{ color: '#E1306C',fontWeight:'650' }}></i>
  </a>
  <a 
    className="cart__icon" 
    href="https://www.facebook.com/profile.php?id=61557310556396"
    target="_blank"
    rel="noopener noreferrer"
  >
    <i className="ri-facebook-line" style={{ color: '#0A66C2',fontWeight:'650'  }}></i>
  </a>
  <a 
    className="cart__icon" 
    href="https://wa.me/6364663787?text=Hello sir"
    target="_blank"
    rel="noopener noreferrer"
  >
    <i className="ri-whatsapp-line" style={{ color: '#25D366',fontWeight:'650'  }}></i>
  </a>
</div>



                <div className="nav__right d-flex align-items-center gap-4">
            
            <>
              <span className="cart__icon" onClick={toggleCart}>
                <i className="ri-shopping-basket-line"></i>
                <span className="cart__badge">{totalQuantity}</span>
              </span>

              <span className="user">
                <Link to="/user-register">
                  <i className="ri-user-line"></i>
                </Link>
              </span>
            </>
          <span className="mobile__menu" onClick={toggleMenu}>
            <i className="ri-menu-line"></i>
          </span>
         
        </div>
        
              </>
              )}
              </>

            ) : (
              <>
                <a  className="header-a" onClick={handleLogout} href="/">Logout</a>
                <span className="mobile__menu" onClick={toggleMenu}>
            <i className="ri-menu-line"></i>
          </span>
              </>
            )}
          </div>
        </div>
      </Container>
      {cartIsVisible && <Carts />}
      
    </header>
  );
};

export default Header;
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";
import "remixicon/fonts/remixicon.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AdminProvider } from './components/Pages/Admin-Section/AdminContext';
ReactDOM.render(
  <React.StrictMode>
    <AdminProvider>
    <App />
    <ToastContainer />
    </AdminProvider>
  </React.StrictMode>,
  document.getElementById("root")
);


import React, { useState, useEffect, useContext, lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import { CartProvider } from '../src/components/Cart/CartContext';
import { DataProvider } from '../src/components/Cart/CartDataManage/dataManageContext';
import { AuthProvider } from './components/Pages/AuthContext';
import { AdminContext } from './components/Pages/Admin-Section/AdminContext';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import ForgotPassword from './components/Pages/Admin-Section/ForgotPassword';
import BASE_URL from './config';
// Lazy load components
const Home = lazy(() => import('./components/Pages/Home'));
const OurProducts = lazy(() => import('./components/Pages/OurProducts'));
const UploadForm = lazy(() => import('./components/Products/AddProducts'));
const MenuItems = lazy(() => import('./components/Pages/MenuItems'));
const Checkout = lazy(() => import('./components/Pages/Checkout'));
const UserRegister = lazy(() => import('./components/Pages/UserAuth/UserRegister'));
const UserLogin = lazy(() => import('./components/Pages/UserAuth/UserLogin'));
const SweetMessage = lazy(() => import('./components/Cart/SweetMessage'));
const Login = lazy(() => import('./components/Pages/Admin-Section/Login'));
const Register = lazy(() => import('./components/Pages/Admin-Section/Register'));
const AdminHome = lazy(() => import('./components/Pages/Admin-Section/AdminHome'));
const ManageProducts = lazy(() => import('./components/Products/ManageProducts'));
const AddMenu = lazy(() => import('./components/Products/AddMenu'));
const ManageMenu = lazy(() => import('./components/Products/ManageMenu'));

function App() {
  const [cart, setCart] = useState([]);
  const [productsFromOurProducts, setProductsFromOurProducts] = useState([]);
  const [isAdminAccessible, setIsAdminAccessible] = useState(false);
  const sendProductsToProvider = (products) => {
    setProductsFromOurProducts(products);
  };
  const { adminEmail: adminEmailFromContext } = useContext(AdminContext);
  useEffect(() => {
    // Check if admin email exists in local storage
    // const adminEmail = localStorage.getItem('adminEmail');
    const adminEmailFromLocalStorage = localStorage.getItem('adminEmail');

    // Use the value from context API if available, otherwise fallback to local storage
    const adminEmail = adminEmailFromContext || adminEmailFromLocalStorage;
    if (adminEmail) {
      // If admin email exists, send request to server to check if it is accessible
      fetch(`${BASE_URL}/api/admin/checkEmail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: adminEmail }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.exists && data.verified) {
            // If email exists and is verified, set isAdminAccessible to true
            setIsAdminAccessible(true);
          } else {
            // If email doesn't exist or is not verified, set isAdminAccessible to false
            setIsAdminAccessible(false);
          }
        })
        .catch((error) => {
          console.error('Error checking admin email:', error);
          setIsAdminAccessible(false);
        });
    } else {
      // If admin email doesn't exist in local storage, set isAdminAccessible to false
      setIsAdminAccessible(false);
    }
  }, [adminEmailFromContext]);
  return (
    <Router>
      <CartProvider>

      <DataProvider products={productsFromOurProducts}>
    <AuthProvider>
        <Header />
        <div className="App">
        <Helmet>
          <title>Mr.Champaran</title>
  
          <meta name="description" content="champaran mutton" />
          <meta name="keywords" content="kadi badi,khichdi" />
          <meta name="description" content="Mr.Champaran - Your one-stop destination for delicious and authentic Champaran mutton dishes, kadi badi, and khichdi. Order now for a delightful dining experience!" />
          <meta name="keywords" content="Mr.Champaran, Champaran mutton, kadi badi, khichdi" />
        </Helmet>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
          {isAdminAccessible ? (
                  <>
                    <Route path="/v1/auth/admin/home" element={<AdminHome />} />
                    {/* <Route path="/v1/auth/admin/ourproducts" element={<AdminOurProducts sendProducts={sendProductsToProvider} />} /> */}
                    <Route path="/v1/auth/admin/addproducts" element={<UploadForm />} />
                    <Route path="/v1/auth/admin/addmenu" element={<AddMenu />} />
                    <Route path="/v1/auth/admin/manageproducts" element={<ManageProducts />} />
                    <Route path="/v1/auth/admin/managemenu" element={<ManageMenu />} />
                    <Route path="*" element={<AdminHome />} />

                  </>
                ):(
                  <>
            <Route path="/" element={<Home />} />
            <Route path="/menu-items" element={<MenuItems />} />
            <Route path="/ourproducts" element={<OurProducts sendProducts={sendProductsToProvider} />} />
            <Route path="/products/addproducts" element={<UploadForm />} />
            <Route path="/products/checkout" element={<Checkout />} />
            <Route path="/user-register" element={<UserRegister />} />
            <Route path="/user-login" element={<UserLogin />} />
            <Route path="/sweetmessage" element={<SweetMessage />} />
            <Route path="/v1/auth/admin/register" element={<Register />} />
                    <Route path="/v1/auth/admin/login" element={<Login />} />
                    <Route path="/v1/auth/admin" element={<Login />} />
                    <Route path="/v1/auth/admin/forgot-password" element={<ForgotPassword />} />
            </>
            )}
          </Routes>
          </Suspense>
        </div>
        <Footer />
        </AuthProvider>
        </DataProvider>
      </CartProvider>
    </Router>
  );
}

export default App;

import React from "react";
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import logo from "../../assets/images/Mr. Champaran-Updated Logo.png";

import { GrInstagram } from "react-icons/gr";

import { GrFacebookOption } from "react-icons/gr";
import ReactWhatsapp from "react-whatsapp";
import whatsapp from '../../assets/whatsapp-svgrepo-com.svg'
import "../Styles/footer.css";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg="3" md="4" sm="6" >
            <div className=" footer__logo text-start">
              <img src={logo} alt="logo" style={{backgroundColor:'white'}} />
             
              <div class="text-container">
  <h1>Experience the magic of Champaran cuisine at</h1>
  <div className="mid-div"><div className="svgicon"></div> <p style={{color:'red',fontWeight:'800',fontSize:'1.6rem'}}>Mr. Champaran </p></div><h1 >Authentic Taste Made Fresh</h1>
</div>
            </div>
          </Col>

          <Col lg="3" md="4" sm="6">
            <h5 className="footer__title">Delivery Time</h5>
            <ListGroup className="deliver__time-list">
              <ListGroupItem className=" delivery__time-item border-0 ps-0">
                <span>Monday - Thursday</span>
                <p>10:00am - 11:00pm</p>
              </ListGroupItem>

              <ListGroupItem className=" delivery__time-item border-0 ps-0">
                <span>Friday - Sunday</span>
                <p>08:00am - 11:00pm</p>
              </ListGroupItem>

              <ListGroupItem className=" delivery__time-item border-0 ps-0">
                <span>Breakfast</span>
                <p>08:00am - 10:00am</p>
              </ListGroupItem>
            </ListGroup>
          </Col>

          <Col lg="3" md="4" sm="6">
            <h5 className="footer__title">Contact</h5>
            <ListGroup className="deliver__time-list">
              <ListGroupItem className=" delivery__time-item border-0 ps-0">
                <p>Location:  Krishna Arcade, Huskur, Virgonagar, Bidarahalli Hobli, Opposite Signature Dreams, Hoskote, Bangalore, Virgonagar, Bangalore</p>
              </ListGroupItem>
              <ListGroupItem className=" delivery__time-item border-0 ps-0">
                <span>Mobile: +91 6364 663 787</span>
              </ListGroupItem>

              <ListGroupItem className=" delivery__time-item border-0 ps-0">
                <span>Email: contact@mrchamparan.com</span>
              </ListGroupItem>
            </ListGroup>
          </Col>

          <Col lg="3" md="4" sm="6">
            <h5 className="footer__title">Newsletter</h5>
            <p className="followtext">Subscribe our newsletter</p>
            <div className="newsletter">
              <input type="email" placeholder="Enter your email" />
             
            </div>
            <div className="newsletter2"> <span>
            Submit  <i class="ri-send-plane-line"></i>
              </span></div>
          </Col>
          
        
        </Row>

        <Row className="mt-5">
          <Col lg="6" md="6">
            <p className="copyright__text">
              Copyright - 2024, All Rights
              Reserved.
            </p>
          </Col>
          <Col lg="6" md="6">
            <div className="social__links d-flex align-items-center gap-4 justify-content-end">
              <p className="followtext m-0">Follow: </p>
              <span>
                {" "}
                <Link to="https://www.facebook.com/profile.php?id=61557310556396">
                <GrFacebookOption />

                </Link>{" "}
              </span>

              <span>
                <Link to="https://www.instagram.com/mr.champaran/">
                <GrInstagram />
                </Link>
              </span>
              <span>
              <span>
                
                <ReactWhatsapp number="6364663787" message="You've got a message from the Mr. Champaran website regarding the testing of their WhatsApp API"  style={{background:'transparent',border:'transparent'}}>
  <img src={whatsapp} alt="" style={{height:'20px',width:'20px',}}/></ReactWhatsapp>
  {/* <ReactWhatsapp number="+919359423529" message="You've got a message from the Mr. Champaran website regarding the testing of their WhatsApp API"   /> */}
  
                </span>
              </span>
              {/* <span>
                {" "}
                <Link to=" https://www.youtube.com/c">
                  <i class="ri-youtube-line"></i>
                </Link>{" "}
              </span>

              <span>
                {" "}
                <Link to=" https://www.linkedin.com/in">
                  <i class="ri-linkedin-line"></i>
                </Link>{" "}
              </span> */}
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;

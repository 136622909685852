// // dataManageContext.js
// import React, { createContext, useContext, useState, useEffect } from 'react';

// const DataContext = createContext();

// export const useData = () => useContext(DataContext);

// export const DataProvider = ({ children, products }) => {
//   const [cart, setCart] = useState([]);

//   // Load cart data from localStorage on component mount
//   useEffect(() => {
//     const storedCart = JSON.parse(localStorage.getItem('cart'));
//     if (storedCart) {
//       setCart(storedCart);
//     }
//   }, []);

//   const saveCartToLocalStorage = (cartData) => {
//     localStorage.setItem('cart', JSON.stringify(cartData));
//   };

//   const addToCart = (productId) => {
//     setCart(prevCart => {
//       const itemIndex = prevCart.findIndex(item => item.id === productId);
//       if (itemIndex !== -1) {
//         const updatedCart = [...prevCart];
//         updatedCart[itemIndex].quantity += 1;
//         saveCartToLocalStorage(updatedCart); // Save updated cart to localStorage
//         return updatedCart;
//       } else {
//         const product = products.find(product => product.id === productId);
//         if (product) {
//           const updatedCart = [...prevCart, { id: product.id, title: product.title, quantity: 1 }];
//           saveCartToLocalStorage(updatedCart); // Save updated cart to localStorage
//           return updatedCart;
//         }
//       }
//       return prevCart;
//     });
//   };

//   const incrementQuantity = (productId) => {
//     const updatedCart = cart.map(item => {
//       if (item.id === productId) {
//         return { ...item, quantity: item.quantity + 1 };
//       }
//       return item;
//     });
//     setCart(updatedCart);
//     saveCartToLocalStorage(updatedCart); // Save updated cart to localStorage
//   };

//   const decrementQuantity = (productId) => {
//     const updatedCart = cart.map(item => {
//       if (item.id === productId && item.quantity > 1) {
//         return { ...item, quantity: item.quantity - 1 };
//       }
//       return item;
//     });
//     setCart(updatedCart);
//     saveCartToLocalStorage(updatedCart); // Save updated cart to localStorage
//   };

//   return (
//     <DataContext.Provider value={{ cart, addToCart, incrementQuantity, decrementQuantity }}>
//       {children}
//     </DataContext.Provider>
//   );
// };

// // working code for price quantity updatedPrice 
// import React, { createContext, useContext, useState, useEffect } from 'react';

// const DataContext = createContext();

// export const useData = () => useContext(DataContext);
// export const DataProvider = ({ children, products }) => {
//   const [cart, setCart] = useState([]);

//   // Load cart data from localStorage on component mount
//   useEffect(() => {
//     const storedCart = JSON.parse(localStorage.getItem('cart'));
//     if (storedCart) {
//       setCart(storedCart);
//     }
//   }, []);

//   const saveCartToLocalStorage = (cartData) => {
//     localStorage.setItem('cart', JSON.stringify(cartData));
//   };

//   const addToCart = (productId) => {
//     setCart(prevCart => {
//       const itemIndex = prevCart.findIndex(item => item.id === productId);
//       if (itemIndex !== -1) {
//         const updatedCart = [...prevCart];
//         updatedCart[itemIndex].quantity += 1;
//         updatedCart[itemIndex].updatedPrice = updatedCart[itemIndex].quantity * updatedCart[itemIndex].price; 
//         saveCartToLocalStorage(updatedCart); // Save updated cart to localStorage
//         return updatedCart;
//       } else {
//         const product = products.find(product => product.id === productId);
//         if (product) {
//           const updatedCart = [...prevCart, { id: product.id, title: product.title, quantity: 1, price: product.price, updatedPrice: product.price  }];
//           saveCartToLocalStorage(updatedCart); // Save updated cart to localStorage
//           return updatedCart;
//         }
//       }
//       return prevCart;
//     });
//   };

//   const incrementQuantity = (productId) => {
//     const updatedCart = cart.map(item => {
//       if (item.id === productId) {
//         const updatedItem = { ...item, quantity: item.quantity + 1 };
//         updatedItem.updatedPrice = updatedItem.quantity * updatedItem.price; // Calculate updatedPrice
//         return updatedItem;
//       }
//       return item;
//     });
//     setCart(updatedCart);
//     saveCartToLocalStorage(updatedCart); // Save updated cart to localStorage
//   };

//   const decrementQuantity = (productId) => {
//     const updatedCart = cart.map(item => {
//       if (item.id === productId && item.quantity > 1) {
//         const updatedItem = { ...item, quantity: item.quantity - 1 };
//         updatedItem.updatedPrice = updatedItem.quantity * updatedItem.price; // Calculate updatedPrice
//         return updatedItem;
//       }
//       return item;
//     });
//     setCart(updatedCart);
//     saveCartToLocalStorage(updatedCart); // Save updated cart to localStorage
//   };


//   return (
//     <DataContext.Provider value={{ cart, addToCart, incrementQuantity, decrementQuantity }}>
//       {children}
//     </DataContext.Provider>
//   );
// };


// //working for quantity price updated price tptalquantity totalamount
// import React, { createContext, useContext, useState, useEffect } from 'react';

// const DataContext = createContext();

// export const useData = () => useContext(DataContext);
// export const DataProvider = ({ children, products }) => {
//   const [cart, setCart] = useState([]);
//   const [totalQuantity, setTotalQuantity] = useState(0);
//   const [totalAmount, setTotalAmount] = useState(0);

//   // Load cart data from localStorage on component mount
//   useEffect(() => {
//     const storedCart = JSON.parse(localStorage.getItem('cart'));
//     if (storedCart) {
//       setCart(storedCart);
//       calculateTotals(storedCart);
//     }
//   }, []);

//   const saveCartToLocalStorage = (cartData) => {
//     localStorage.setItem('cart', JSON.stringify(cartData));
//     calculateTotals(cartData);
//   };

//   const calculateTotals = (cartData) => {
//     let quantity = 0;
//     let amount = 0;
//     cartData.forEach(item => {
//       quantity += item.quantity;
//       amount += item.updatedPrice;
//     });
//     setTotalQuantity(quantity);
//     setTotalAmount(amount);
//     localStorage.setItem('totalQuantity', quantity);
//     localStorage.setItem('totalAmount', amount);
//   };

//   const addToCart = (productId) => {
//     setCart(prevCart => {
//       const itemIndex = prevCart.findIndex(item => item.id === productId);
//       if (itemIndex !== -1) {
//         const updatedCart = [...prevCart];
//         updatedCart[itemIndex].quantity += 1;
//         updatedCart[itemIndex].updatedPrice = updatedCart[itemIndex].quantity * updatedCart[itemIndex].price; 
//         saveCartToLocalStorage(updatedCart); // Save updated cart to localStorage
//         return updatedCart;
//       } else {
//         const product = products.find(product => product.id === productId);
//         if (product) {
//           const updatedCart = [...prevCart, { id: product.id, title: product.title, quantity: 1, price: product.price, updatedPrice: product.price  }];
//           saveCartToLocalStorage(updatedCart); // Save updated cart to localStorage
//           return updatedCart;
//         }
//       }
//       return prevCart;
//     });
//   };

//   const incrementQuantity = (productId) => {
//     const updatedCart = cart.map(item => {
//       if (item.id === productId) {
//         const updatedItem = { ...item, quantity: item.quantity + 1 };
//         updatedItem.updatedPrice = updatedItem.quantity * updatedItem.price; // Calculate updatedPrice
//         return updatedItem;
//       }
//       return item;
//     });
//     setCart(updatedCart);
//     saveCartToLocalStorage(updatedCart); // Save updated cart to localStorage
//   };

//   const decrementQuantity = (productId) => {
//     const updatedCart = cart.map(item => {
//       if (item.id === productId && item.quantity > 1) {
//         const updatedItem = { ...item, quantity: item.quantity - 1 };
//         updatedItem.updatedPrice = updatedItem.quantity * updatedItem.price; // Calculate updatedPrice
//         return updatedItem;
//       }
//       return item;
//     });
//     setCart(updatedCart);
//     saveCartToLocalStorage(updatedCart); // Save updated cart to localStorage
//   };

//   return (
//     <DataContext.Provider value={{ cart, totalQuantity, totalAmount, addToCart, incrementQuantity, decrementQuantity }}>
//       {children}
//     </DataContext.Provider>
//   );
// };

//working code for all function A to Z
import React, { createContext, useContext, useState, useEffect } from 'react';

const DataContext = createContext();

export const useData = () => useContext(DataContext);
export const DataProvider = ({ children, products }) => {
  const [cart, setCart] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  // Load cart data from localStorage on component mount
  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem('cart'));
    if (storedCart) {
      setCart(storedCart);
      calculateTotals(storedCart);
    }
  }, []);

  const saveCartToLocalStorage = (cartData) => {
    localStorage.setItem('cart', JSON.stringify(cartData));
    calculateTotals(cartData);
  };

  const calculateTotals = (cartData) => {
    let quantity = 0;
    let amount = 0;
    cartData.forEach(item => {
      quantity += item.quantity;
      amount += item.updatedPrice;
    });
    setTotalQuantity(quantity);
    setTotalAmount(amount);
    localStorage.setItem('totalQuantity', quantity);
    localStorage.setItem('totalAmount', amount);
  };

  const addToCart = (productId) => {
    setCart(prevCart => {
      const itemIndex = prevCart.findIndex(item => item.id === productId);
      if (itemIndex !== -1) {
        const updatedCart = [...prevCart];
        updatedCart[itemIndex].quantity += 1;
        updatedCart[itemIndex].updatedPrice = updatedCart[itemIndex].quantity * updatedCart[itemIndex].price; 
        saveCartToLocalStorage(updatedCart); // Save updated cart to localStorage
        return updatedCart;
      } else {
        const product = products.find(product => product.id === productId);
        if (product) {
          const updatedCart = [...prevCart, { id: product.id, title: product.title, quantity: 1, price: product.price, path: product.path, 
          filename: product.filename, updatedPrice: product.price  }];
          saveCartToLocalStorage(updatedCart); // Save updated cart to localStorage
          return updatedCart;
        }
      }
      return prevCart;
    });
  };

  const incrementQuantity = (productId) => {
    const updatedCart = cart.map(item => {
      if (item.id === productId) {
        const updatedItem = { ...item, quantity: item.quantity + 1 };
        updatedItem.updatedPrice = updatedItem.quantity * updatedItem.price; // Calculate updatedPrice
        return updatedItem;
      }
      return item;
    });
    setCart(updatedCart);
    saveCartToLocalStorage(updatedCart); // Save updated cart to localStorage
  };

  const decrementQuantity = (productId) => {
    const updatedCart = cart.map(item => {
      if (item.id === productId) {
        const updatedItem = { ...item, quantity: item.quantity - 1 };
        if (updatedItem.quantity === 0) {
          return null;
        } else {
          updatedItem.updatedPrice = updatedItem.quantity * updatedItem.price; // Calculate updatedPrice
          return updatedItem;
        }
      }
      return item;
    }).filter(Boolean); // Filter out null values
    setCart(updatedCart);
    saveCartToLocalStorage(updatedCart); // Save updated cart to localStorage
  };

  const removeFromCart = (productId) => {
    const updatedCart = cart.filter(item => item.id !== productId);
    setCart(updatedCart);
    saveCartToLocalStorage(updatedCart); // Save updated cart to localStorage
  };

  return (
    <DataContext.Provider value={{ cart, totalQuantity, totalAmount, addToCart, incrementQuantity, decrementQuantity, removeFromCart }}>
      {children}
    </DataContext.Provider>
  );
};

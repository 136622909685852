import React, { useContext } from "react";
import { ListGroup } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import "../Styles/shopping-cart.css";
import { useData } from "./CartDataManage/dataManageContext";
import { CartContext } from "./CartContext";
import '../Styles/cart-page.css'
import BASE_URL from '../../../src/config'

const Carts = () => {
  const { cartIsVisible, toggleCart } = useContext(CartContext);
  const { cart, incrementQuantity, decrementQuantity, totalAmount, removeFromCart } = useData();
  const navigate = useNavigate();

  const handleCloseCart = () => {
    toggleCart();
  };

  const handleOpenCheckout = () => {
    handleCloseCart();
    navigate('/products/checkout');
  };

  return (
    <div className={`cart__container ${cartIsVisible ? "visible" : ""}`}>
      <ListGroup className="cart">
        <div className="cart__close">
          <span onClick={handleCloseCart}>
            <i className="ri-close-fill"></i>
          </span>
        </div>

        {cart.length === 0 ? (
          <p className="text-center" >Cart is empty</p>
        ) : (
          <div>
            {cart.map(item => (
              <div key={item.id} className="new-cartdata-product">
                <div className="cartdata-product-left">
                  <div className="cartdata-product-left-div1">
                    <img src={`${BASE_URL}/${item.path}`} alt={item.filename} style={{ height: '50px', width: '60px' }} />
                  </div>
                  <div className="cartdata-product-left-div2">{item.title}</div>
                </div>
                <div className="cartdata-product-right">
                  <div className="cartdata-product-right-item">Rs.{item.price}</div>
                  <div className="cartdata-product-right-item"><button onClick={() => decrementQuantity(item.id)} className="btn btn-primary">Qty-</button></div>
                  <div className="cartdata-product-right-item">{item.quantity}</div>
                  <div className="cartdata-product-right-item"><button onClick={() => incrementQuantity(item.id)} className="btn btn-primary">Qty+</button></div>
                  <div className="cartdata-product-right-item"><button onClick={() => removeFromCart(item.id)} className="btn btn-danger">X</button></div>
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="cart__bottom d-flex align-items-center justify-content-between">
          {cart.length > 0 && <h6>Subtotal :&nbsp;Rs-{totalAmount.toFixed(2)}</h6>}
          {cart.length > 0 && (
            <Link to="/products/checkout">
              <button onClick={handleOpenCheckout} className="btn btn-secondary">Checkout</button>
            </Link>
          )}
        </div>
      </ListGroup>
    </div>
  );
};

export default Carts;

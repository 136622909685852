import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BASE_URL from '../../../config';

const ForgotPassword = () => {
  const [formData, setFormData] = useState({
    email: '',
  });
  const [otpFieldVisible, setOtpFieldVisible] = useState(false);
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState(''); // State for newPassword
  const [errors, setErrors] = useState({});

  const { email } = formData;
  const navigate = useNavigate();

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async e => {
    e.preventDefault();
    try {
      const res = await axios.post(`${BASE_URL}/forgot-password`, { email });
      console.log(res.data);
      if (res.status === 200) {
        setOtpFieldVisible(true);
        toast.success('OTP sent successfully');
      }
    } catch (error) {
      console.error(error.response.data);
      if (
        error.response.status === 400 &&
        error.response.data.error === 'OTP already sent for this email. Please verify.'
      ) {
        setOtpFieldVisible(true);
        toast.error('OTP already sent for this email. Please verify.');
      } else {
        toast.error('Registration failed user not found', error.response.data);
      }
    }
  };

  const onOtpSubmit = async e => {
    e.preventDefault();
    try {
      const res = await axios.post(`${BASE_URL}/verify-and-update-password`, {
        email,
        otp,
        newPassword, // Include newPassword in the request
      });
      console.log(res.data);
      const { message } = res.data;
      toast.success(message);
      navigate('/v1/auth/admin/login');
    } catch (error) {
      console.error(error.response.data);
      toast.error('Forgot Password failed: invalid or expired OTP');
    }
  };

  return (
    <div className="login-container">
      <h2>Forgot Password</h2>
      {!otpFieldVisible ? (
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email *</label>
            <input
              type="email"
              placeholder="Email"
              name="email"
              value={email}
              onChange={handleChange}
              className={`form-control ${errors.email && 'is-invalid'}`}
              required
            />
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-primary">
              Request OTP
            </button>
          </div>
        </form>
      ) : (
        <form onSubmit={onOtpSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email *</label>
            <input
              type="email"
              placeholder="Email"
              name="email"
              value={email}
              onChange={e => setOtp(e.target.value)}
              className={`form-control ${errors.email && 'is-invalid'}`}
              required
              disabled
            />
          </div>
          <div className="form-group">
            <label htmlFor="otp">OTP*</label>
            <input
              type="text"
              placeholder="Enter OTP"
              name="otp"
              value={otp}
              onChange={e => setOtp(e.target.value)}
              className={`form-control ${errors.otp && 'is-invalid'}`}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="newPassword">New Password*</label>
            <input
              type="password" // Change input type to password
              placeholder="Enter New Password"
              name="newPassword"
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)} // Handle onChange for newPassword
              className={`form-control ${errors.newPassword && 'is-invalid'}`}
              required
            />
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-primary">
              Verify OTP
            </button>
          </div>
        </form>
      )}
      <p>
        If you don't have an account, please <a href="/user-register">Register</a>.
      </p>
    </div>
  );
};

export default ForgotPassword;

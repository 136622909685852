// import React, { createContext, useState } from 'react';

// export const CartContext = createContext();

// export const CartProvider = ({ children }) => {
//   const [cartIsVisible, setCartIsVisible] = useState(false);

//   const toggleCart = () => {
//     setCartIsVisible(prevState => !prevState); // Toggle cart visibility
//   };

//   return (
//     <CartContext.Provider value={{ cartIsVisible, toggleCart }}>
//       {children}
//     </CartContext.Provider>
//   );
// };


// // this is working code for 2 context api
// import React, { createContext, useState } from 'react';

// export const CartContext = createContext();

// export const CartProvider = ({ children }) => {
//   const [cartIsVisible, setCartIsVisible] = useState(false);

//   const toggleCart = () => {
//     setCartIsVisible(prevState => !prevState); // Toggle cart visibility
//   };

//   return (
//     <CartContext.Provider value={{ cartIsVisible, toggleCart }}>
//       {children}
//     </CartContext.Provider>
//   );
// };




// this is working code for 2 context api
import React, { createContext, useState } from 'react';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartIsVisible, setCartIsVisible] = useState(false);

  const toggleCart = () => {
    setCartIsVisible(prevState => !prevState); // Toggle cart visibility
  };

  return (
    <CartContext.Provider value={{ cartIsVisible, toggleCart }}>
      {children}
    </CartContext.Provider>
  );
};
